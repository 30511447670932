import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  model,
} from '@angular/core';
import { input } from '@angular/core';

const time = '195ms cubic-bezier(0.4, 0.0, 0.6, 1)';
@Component({
  selector: 'action-box',
  templateUrl: './action-box.component.html',
  styleUrls: ['./action-box.component.scss'],
  animations: [
    trigger('animate', [
      state(
        'void',
        style({ opacity: 0, transform: 'translate3d(0, 100%, 0)' }),
      ),
      transition('void <=> *', [animate(time)]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class ActionBoxComponent {
  targetElm = input<HTMLElement | Element>(
    this.document.scrollingElement as any,
    {
      alias: 'elm',
    },
  );
  isShow = model(false);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  goTop() {
    this.targetElm().scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  goPrev() {
    history.back();
    this.isShow.set(false);
  }
}
