import { CommonModule } from '@angular/common';
import {
  Component,
  HostBinding,
  inject,
  Inject,
  Injector,
  NgZone,
  PLATFORM_ID,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';

import {
  appGlobal,
  BaseService,
  CacheService,
  I18nService,
  InMemoryScrollService,
  LazyLoadErrorService,
} from '@nghedgehog/angular-ui';
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { environment } from '../environments/environment';
import { FB_BadgeColor } from '../initiator';
import { PhotoGalleryComponent } from './core/components/my-gallery/photo-gallery/photo-gallery.component';
import { InlineStyleComponent } from './styles/inline-style/inline-style.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './carousel.scss', './banner.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LoadingBarModule,
    InlineStyleComponent,
    PhotoGalleryComponent,
  ],
})
export class AppComponent {
  @HostBinding('class') className = 'bg-gray-50 text-base';

  showThemeSelector = false;

  isBot = this._base.isBot;
  userAgent = navigator?.userAgent;
  language$ = this._i18n.language$;

  FB_BadgeColor = FB_BadgeColor;
  fbLink = `https://m.me/${environment.fbPageId}`;

  get fbLinkShow$() {
    return this._base.fbLinkShow$;
  }

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _base: BaseService,
    private _i18n: I18nService,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: any,
    private _cache: CacheService,
    private injector: Injector,
    private _lazyLoadErrorService: LazyLoadErrorService,
  ) {
    inject(InMemoryScrollService);

    appGlobal.zone = this.zone;
    appGlobal.platformId = this.platformId;
    appGlobal.injector = this.injector;
    appGlobal.viewContainerRef = this._viewContainerRef;

    // inject i18n language to prevent circular dependency
    this._cache.setLocalFn(() => this._i18n.language);
    this._cache.checkAppVersion();
    this._lazyLoadErrorService.listen();

    window['enableTheme'] = () => {
      this.zone.run(() => {
        this.showThemeSelector = !this.showThemeSelector;
      });
    };

    window['testError'] = () => {
      throw new Error('test Error');
    };
  }
}
