import { Routes } from '@angular/router';

import { PageResolve } from '@nghedgehog/angular-ui';

import { HomeComponent } from './home.component';
import { routes as indexRoutes } from './routes/index/index.routes';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: '首頁',
    },
    children: [
      {
        path: '',
        loadChildren: () => indexRoutes,
      },
      {
        path: 'about',
        loadChildren: () =>
          import('./routes/about/about.routes').then((m) => m.routes),
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./routes/blog/blog.routes').then((m) => m.routes),
      },
      {
        path: 'page',
        loadChildren: () =>
          import('./routes/other-page/other-page.routes').then((m) => m.routes),
      },
    ],
  },
];
