import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from '../../../environments/environment';
import { I18nService } from '@nghedgehog/angular-ui';
import { EMPTY, NEVER, of, throwError } from 'rxjs';

@Injectable()
export class AwooInterceptor implements HttpInterceptor {
  i18n = inject(I18nService);

  get isChinese() {
    const language = this.i18n.language;
    return language === 'zh' || language === 'zh-tw';
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.startsWith(environment.awoo.url)) {
      return next.handle(req);
    }

    if (!this.isChinese) {
      const mockResponse = new HttpResponse({
        body: {
          errcode: '',
          errmsg: '',
          requestId: '',
          result: {
            tags: [],
            products: {
              'directions:v': { products: [] },
              'directions:h': { products: [] },
            },
          },
        },
      });
      return of(mockResponse);
    }

    const serverReq = req.clone({
      setHeaders: {
        authorization: `Bearer ${environment.awoo.token}`,
      },
    });

    return next.handle(serverReq);
  }
}
