<ngx-loading-bar color="#30bead"></ngx-loading-bar>
<router-outlet></router-outlet>

<app-inline-style></app-inline-style>
<app-photo-gallery></app-photo-gallery>
<div hidden>
  isBot: {{ isBot }} <br />
  userAgent: {{ userAgent }} <br />
  language: {{ language$ | async }} <br />
</div>
