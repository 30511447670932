import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  AllMrtCategoriesGQL,
  AllMrtsGQL,
  MrtCategory,
  SearchLocationsGQL,
} from '@alan-apps/data-access';
import { toNodes } from '@alan-apps/utils';
import { BaseHttpService, cache, ICache } from '@nghedgehog/angular-ui';

@Injectable({
  providedIn: 'root',
})
export class MrtService implements ICache {
  readonly storageKey = 'MrtService';

  constructor(
    private _http: BaseHttpService,
    private allMrtsGQL: AllMrtsGQL,
    private allMrtCategoriesGQL: AllMrtCategoriesGQL,
    private searchLocations: SearchLocationsGQL,
  ) {}

  @cache()
  list(category: string, onlyProperty = false) {
    return this._http.apollo(this.allMrtsGQL.fetch({ category, onlyProperty }));
  }

  @cache()
  getAllCategory(onlyProperty = false) {
    return this._http.apollo(this.allMrtCategoriesGQL.fetch({ onlyProperty }));
  }

  @cache()
  getAllMassTransit() {
    return this._http
      .apollo(
        this.searchLocations.fetch({
          query: { type: 'massTransit' },
          onlyProperty: true,
        }),
      )
      .pipe(toNodes()) as Observable<MrtCategory[]>;
  }
}
