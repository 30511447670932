import { AsyncPipe, DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  Optional,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { map, switchMap, timer } from 'rxjs';

import { PageService } from '@nghedgehog/angular-ui';

import {
  THEME_COLOR_LIST,
  ThemeModel,
  ThemeService,
} from '../../core/components/theme-color-selector';
import { LazyStyleComponent } from './lazy-style/lazy-style.component';

@Component({
  selector: 'app-inline-style',
  template: `
    <ng-container #anchor></ng-container>
    @defer {
    @if (lazy$ | async) {
      <app-lazy></app-lazy>
    }
    }
    `,
  styleUrls: ['./inline-style.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [LazyStyleComponent, AsyncPipe],
})
export class InlineStyleComponent implements OnInit {
  @ViewChild('anchor', { read: ViewContainerRef }) anchor!: ViewContainerRef;

  // * use that way to make style lazy apply to dom
  lazy$ = timer(100).pipe(map(() => true));

  constructor(
    private _renderer: Renderer2,
    private _theme: ThemeService,
    private _page: PageService,
    @Optional() @Inject(THEME_COLOR_LIST) public themes: ThemeModel[],
    @Inject(DOCUMENT) private document: Document,
    private _meta: Meta,
  ) {}

  async ngOnInit() {
    if (this._page.isBrowser) {
      // this event never stop, so not need unsubscribe
      this._theme.currentTheme$
        .pipe(switchMap((theme) => this.loadTheme(theme).then(() => theme)))
        .subscribe();
    } else {
      const theme = this._theme.currentTheme$.value;
      await this.loadTheme(theme);
    }
  }

  private async loadTheme(theme: ThemeModel) {
    const { Component } = await theme.loadChildren();

    this.anchor.createComponent(Component);

    this._meta.updateTag({ name: 'theme-color', content: theme.primary });

    const hostElm = this.document.documentElement;

    this._renderer.removeAttribute(
      hostElm,
      theme.name === 'light' ? 'dark' : 'light',
    );

    this._renderer.setAttribute(hostElm, theme.name, '');
  }
}
