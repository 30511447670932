import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { LoadingBlockDirective, SafePipe } from '@nghedgehog/angular-ui';

import { ImgPipe } from '../../../pipe/img.pipe';
import { MyGalleryService } from '../my-gallery.service';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss'],
  standalone: true,
  imports: [LoadingBlockDirective, NgClass, SafePipe, ImgPipe],
})
export class PhotoGalleryComponent implements AfterViewInit {
  @ViewChild('item') item!: TemplateRef<any>;
  @ViewChild('gallery') gallery!: TemplateRef<any>;

  constructor(private _gallery: MyGalleryService) {}

  ngAfterViewInit() {
    this._gallery.setTemplate(this.item, this.gallery);
  }
}
