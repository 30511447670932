<ng-template #gallery let-item>
  <div class="w-[80%] h-[80%] grid content-center">
    <img
      class="object-contain w-full h-full photo"
      [src]="item.file | img | safe: 'url'"
      [attr.loading]="'lazy'"
      draggable="false"
      />

    @if (item.subject) {
      <div
        class="content border h-full elevation-2 p-2 flex flex-col"
        >
        <h4>{{ item.subject }}</h4>
        <h6 class="overflow-auto border-top content-text h-full">
          {{ item.body }}
        </h6>
      </div>
    }
  </div>
</ng-template>

<ng-template #item let-item let-i="index" let-currentIndex="currentIndex">
  <div
    class="img bg-cover bg-no-repeat bg-center"
    [ngClass]="{ selected: i === currentIndex }"
    [style.background-image]="item.file | img: 'mq' | safe: 'background-image'"
  ></div>
</ng-template>
