<!-- https://picsum.photos/600/400/?random -->
<!--  [hidden]="true" -->
<header class="transition-long relative">
  <apps-float-menu [menus]="menus$ | async"> </apps-float-menu>
</header>
<main class="flex flex-col w-full justify-end min-h-screen">
  <div class="flex-auto outlet" [class.isHome]="isHome">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-home-footer
    [data]="{ copyright: title }"
    [menus]="menus$ | async"
  ></app-home-footer>
</main>

<action-box [elm]="document.scrollingElement" [isShow]="isShowTop">
  <!-- <button back-content mat-mini-fab color="accent">
    <mat-icon class="text-white">keyboard_return</mat-icon>
  </button> -->
  <button top-content mat-mini-fab color="primary">top</button>
</action-box>
