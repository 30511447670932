import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

import { MenuModel } from '@alan-apps/data-access';
import { BaseService, I18nLinkPipe } from '@nghedgehog/angular-ui';
import { TranslateModule } from '@ngx-translate/core';
import { input } from '@angular/core';

@Component({
  selector: '[drop-down-item]',
  templateUrl: './menu-drop-down-block.component.html',
  styleUrls: ['./menu-drop-down-block.component.scss'],
  standalone: true,
  imports: [
    RouterLinkActive,
    I18nLinkPipe,
    RouterLink,
    CommonModule,
    forwardRef(() => MenuDropDownBlockComponent),
    TranslateModule,
  ],
})
export class MenuDropDownBlockComponent implements OnInit {
  menu = input<MenuModel | undefined>();

  hasChild = false;

  constructor(
    public _base: BaseService,
    private _router: Router,
  ) {}

  ngOnInit() {
    this.hasChild = this.menu().children && this.menu().children.length > 0;
  }

  on() {
    if (this.hasChild) {
      return;
    }

    this._router.navigate(this.menu().url as string[], {
      queryParams: this.menu().queryParams,
    });
    this._base.toggleMenu();
  }
}
