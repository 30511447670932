import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { MenuModel } from '@alan-apps/data-access';
import {
  AuthService,
  BaseService,
  I18nLinkPipe,
  SafePipe,
} from '@nghedgehog/angular-ui';
import { TranslateModule } from '@ngx-translate/core';

import { constants } from '../../../../../environments/constants';
import { zoneCompare } from '../../../../core/decorators/zone.compare.decorator';
import { ImgPipe } from '../../../../core/pipe/img.pipe';
import { I18nMenuComponent } from './i18n-menu/i18n-menu.component';
import { MenuDropDownComponent } from './menu-drop-down/menu-drop-down.component';
import { MenuIconComponent } from './menu-icon/menu-icon.component';
import { input } from '@angular/core';

@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    I18nMenuComponent,
    I18nLinkPipe,
    RouterLink,
    MenuDropDownComponent,
    MenuIconComponent,
    AsyncPipe,
    TranslateModule,
    SafePipe,
    ImgPipe,
  ],
})
export class HomeMenuComponent {
  private _auth = inject(AuthService);
  public _base = inject(BaseService);
  isSmall = input(false);

  isFixed = input(false);

  isAni = input(false);

  showMenu = input(true);

  menus = input<MenuModel[] | undefined>();

  rootData = constants.rootData;

  user = this._auth.user;
}
