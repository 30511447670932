import { AsyncPipe, DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  inject,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

import {
  catchError,
  forkJoin,
  map,
  of,
  retry,
  shareReplay,
  startWith,
} from 'rxjs';

import { AutoDestroy } from '@nghedgehog/core';

import { constants } from '../../../environments/constants';
import { AwooService } from '../../core/service/awoo.service';
import { DepartmentService } from '../../core/service/department.service';
import { FloatMenuComponent } from './components/float-menu/float-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuModel } from '@alan-apps/data-access';
import { BaseService } from '@nghedgehog/angular-ui';
import { ActionBoxComponent } from '../../core/components/action-box/action-box.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FloatMenuComponent,
    RouterOutlet,
    FooterComponent,
    AsyncPipe,
    ActionBoxComponent,
  ],
})
export class HomeComponent extends AutoDestroy {
  @HostBinding('class') className = 'flex flex-col overflow-hidden';

  @ViewChild('containerElm', { read: ElementRef }) elm: ElementRef;
  @ViewChild(FloatMenuComponent, { static: true })
  floatMenu: FloatMenuComponent;

  private awoo = inject(AwooService);
  private readonly initMenu = this.getMenus();

  menus$ = forkJoin([
    this.departmentService.all(),
    this.awoo.popularTags$,
  ]).pipe(
    map(([departments, popularTags]) => {
      const departmentMenus = departments.map<MenuModel>((department) => {
        return {
          title: department.name,
          url: ['/about', 'department', department.id],
        };
      });

      const popularTagMenus = popularTags.map<MenuModel>((tag) => {
        return {
          title: tag.text,
          url: [tag.fullLink],
          hiddenInFooter: true,
        };
      });

      return this.getMenus({
        departments: departmentMenus,
        tags: popularTagMenus,
      });
    }),
    retry({ count: 1, delay: 1000 }),
    catchError(() => {
      return of(
        this.getMenus({
          departments: [
            {
              title: '小草店',
              url: ['/about', 'department', '00001'],
            },
          ],
          tags: [],
        }),
      );
    }),
    shareReplay(1),
    startWith(this.initMenu),
  );

  title: string;

  get isHome() {
    return this.floatMenu.isHome;
  }
  get isShowTop() {
    return this.floatMenu.isShowTop;
  }

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private _base: BaseService,
    private departmentService: DepartmentService,
    private _route: ActivatedRoute,
  ) {
    super();
    this._base.currentPath = this._route.snapshot.data['rootURL'];
    this.title = constants.rootData.info.title;
  }

  private getMenus(options?: {
    departments: MenuModel[];
    tags: MenuModel[];
  }): MenuModel[] {
    const { departments = [], tags = [] } = options || {};
    return [
      {
        title: '關於我們',
        url: ['/about'],
      },

      {
        title: '課程介紹',
        url: ['/properties'],
        children: [
          {
            title: '課程介紹',
            url: ['/properties', 'page'],
          },
          {
            title: '請假補課＆退款',
            url: ['/properties', 'advance'],
          },
        ],
      },
      {
        title: '好文分享',
        url: ['/blog'],
      },
      {
        title: '聯絡我們',
        url: ['/contact'],
      },
    ];
  }

  // getRouteAnimationData() {
  //   const snapshot = this.contexts.getContext('primary')?.route?.snapshot;
  //   const animate = snapshot?.data?.['animation'];
  //   return animate || this._route.snapshot.url;
  // }
}
