<ul class="choice-menu font-bold">
  <li class="lg:hidden mt-6 bg-white/50 rounded p-4">
    <img [src]="rootData.info.logo | img | safe: 'url'" class="rounded mr-2" />
  </li>
  <li>
    <a
      [routerLink]="['/'] | i18nLink"
      (click)="_base.toggleMenu()"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      {{ '首頁' | translate }}
    </a>
  </li>
  @for (menu of menus(); track menu) {
    <li drop-down-item [menu]="menu"></li>
  }
</ul>
