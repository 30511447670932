import { CDK_DRAG_CONFIG, DragDropConfig } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideRouter,
  Router,
  withComponentInputBinding,
  withInMemoryScrolling,
  withViewTransitions,
} from '@angular/router';

import { zhTW } from 'date-fns/locale';
import { NgxRxAlertService } from 'ngx-rx-alert';
import {
  NGX_RX_MODAL_ADDITION_CHECK_ON_ESC,
  NGX_RX_MODAL_CLOSE,
} from 'ngx-rx-modal';
import { firstValueFrom } from 'rxjs';

import {
  ApiInterceptor,
  APP_PAGINATION_DEFAULT_TAKE,
  APP_TITLE,
  APP_URL,
  BEFORE_UNLOAD_FN,
  BEFORE_UNLOAD_MESSAGE,
  CURRENT_LANG_GETTER,
  FIREBASE,
  GEOCODING_KEY,
  I18nService,
  LazyLoadErrorService,
  NUMBER_ONLY,
  TranslateLoaderFactory,
  TWDateFnsAdapter,
} from '@nghedgehog/angular-ui';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';

import { routes } from './app.routes';
import { GraphQLModule } from './graphql.module';
import { MyCloseComponent } from './core/components/my-close/my-close.component';
import { AwooInterceptor } from './core/interceptor/awoo.interceptor';
import {
  THEME_COLOR_LIST,
  ThemeModel,
} from './core/components/theme-color-selector';
import { constants } from '../environments/constants';
import { environment } from '../environments/environment';

const themeColors: ThemeModel[] = [
  {
    name: 'light',
    isLoad: false,
    primary: '#ffa726',
    icon: 'light_mode',
    loadChildren: () => import('./styles/inline-style/light-style'),
  },
  {
    name: 'dark',
    isLoad: false,
    primary: '#fb8c00',
    icon: 'dark_mode',
    loadChildren: () => import('./styles/inline-style/dark-style'),
  },
];

export const TW_FORMATS = {
  parse: {
    dateInput: 'taiwan-date',
  },
  display: {
    dateInput: 'taiwan-date',
    monthYearLabel: 'taiwan-date MMM',
    dateA11yLabel: 'taiwan-date',
    monthYearA11yLabel: 'taiwan-date MMM',
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      // * ssr
      // withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling(),
      withViewTransitions({ skipInitialTransition: true }),
      withComponentInputBinding(),
    ),
    // * ssr
    // provideClientHydration(),
    provideAnimationsAsync(),
    //
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: TranslateLoaderFactory,
          deps: [HttpClient],
        },
      }),
      HttpClientModule,
      HttpClientJsonpModule,
      GraphQLModule,
    ),
    {
      provide: APP_URL,
      useValue: {
        base: environment.siteURL,
        nonAuthentication: constants.url.nonAuthentication,
      },
    },
    {
      provide: APP_PAGINATION_DEFAULT_TAKE,
      useValue: constants.defaultTake,
    },
    {
      provide: FIREBASE,
      useValue: environment.firebase,
    },
    {
      provide: APP_TITLE,
      useValue: () => constants.rootData.info.title,
    },
    {
      provide: CURRENT_LANG_GETTER,
      useFactory: (i18n: I18nService) => () => {
        return i18n.language;
      },
      deps: [I18nService],
    },
    { provide: CDK_DRAG_CONFIG, useValue: { zIndex: 10000 } as DragDropConfig },
    { provide: NUMBER_ONLY, useValue: { min: 0, max: 2147483647 } },
    { provide: THEME_COLOR_LIST, useValue: themeColors },
    {
      provide: BEFORE_UNLOAD_FN,
      useFactory: (_alc: NgxRxAlertService) => (message: string) => {
        return _alc.confirm(message, {
          noRedirect: true,
          backdropStyle: {
            'z-index': 5000,
          },
        });
      },
      deps: [NgxRxAlertService],
    },
    {
      provide: BEFORE_UNLOAD_MESSAGE,
      useFactory: (translate: TranslateService) => {
        return firstValueFrom(translate.get('內容尚未儲存，確定要離開嗎？'));
      },
      deps: [TranslateService],
    },
    {
      provide: NGX_RX_MODAL_ADDITION_CHECK_ON_ESC,
      useFactory: (document: Document) => {
        return () => {
          const overlayExit = document.querySelector('.cdk-overlay-backdrop');
          return !overlayExit;
        };
      },
      deps: [DOCUMENT],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        extractor: (error, defaultExtractor) => {
          if (
            error instanceof Error &&
            (error.message.includes('ChunkLoadError') ||
              (error as any)?.rejection?.name === 'ChunkLoadError')
          ) {
            console.log('ignore ChunkLoadError');
            return 'ChunkLoadError load error will reload page';
          }
          return defaultExtractor(error);
        },
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        //
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    LazyLoadErrorService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AwooInterceptor, multi: true },
    { provide: NGX_RX_MODAL_CLOSE, useValue: MyCloseComponent },
    // material
    { provide: MAT_DATE_LOCALE, useValue: zhTW },
    { provide: GEOCODING_KEY, useValue: environment.geocodingKey },
    { provide: MAT_DATE_FORMATS, useValue: TW_FORMATS },
    {
      provide: DateAdapter,
      useClass: TWDateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        // appearance: 'outline',
        color: 'accent',
        subscriptSizing: 'dynamic',
      } as MatFormFieldDefaultOptions,
    },
  ],
};
