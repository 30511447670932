export const environment = {
  production: true,
  preview: false,
  siteURL: 'https://www.ahome.com.tw',
  firebase: {
    apiKey: 'AIzaSyA43v1q3M5yF473WOpnvuTsRhd9DwKAHFw',
    authDomain: 'orange-house-20230914.firebaseapp.com',
    projectId: 'orange-house-20230914',
    storageBucket: 'orange-house-20230914.appspot.com',
    messagingSenderId: '1068332375015',
    appId: '1:1068332375015:web:21443a7d91ea29908abb42',
  },
  geocodingKey: 'AIzaSyChtFKZtsJD9SddgrHC798277jCPXghbkE',
  fbPageId: '100083525340824',
  fbAppId: '101115609238001',
  awoo: {
    url: 'https://api.awoo.org/nununi',
    id: 2204912200,
    token: 'cfe8206976cd4d9ccd4314c5f4aebbb3a1e6dbe0',
  },
};
