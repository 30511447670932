@if (isShow()) {
  <div class="flex" @animate>
    <div class="mr-2" (click)="goPrev()">
      <ng-content select="[back-content]"></ng-content>
    </div>
    <div (click)="goTop()">
      <ng-content select="[top-content]"></ng-content>
    </div>
  </div>
}
