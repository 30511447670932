import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { BaseService, I18nLinkPipe, SafePipe } from '@nghedgehog/angular-ui';
import { TranslateModule } from '@ngx-translate/core';

import { ImgPipe } from '../../../../../core/pipe/img.pipe';
import { MenuDropDownBlockComponent } from './menu-drop-down-block/menu-drop-down-block.component';
import { MenuModel } from '@alan-apps/data-access';
import { constants } from '../../../../../../environments/constants';
import { environment } from '../../../../../../environments/environment';
import { input } from '@angular/core';

@Component({
  selector: 'app-menu-drop-down',
  templateUrl: './menu-drop-down.component.html',
  styleUrls: ['./menu-drop-down.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    RouterLinkActive,
    I18nLinkPipe,
    RouterLink,
    MenuDropDownBlockComponent,
    TranslateModule,
    SafePipe,
    ImgPipe,
  ],
})
export class MenuDropDownComponent {
  menus = input<MenuModel[] | undefined>();

  rootData = constants.rootData;

  fbLink = `https://www.facebook.com/profile.php?id=${environment.fbPageId}`;

  constructor(public _base: BaseService) {}
}
